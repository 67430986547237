<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="3" lg="3" class="position-relative">
        <div style="height: 100%">
          <v-tabs class="products-tabs" v-model="tab" show-arrows :vertical="windowWidth > 959" :style="{top: navbarHeight}" color="#018ABE">
            <v-tab class="products-tab" v-for="(category, index) of categories" :key="'tab' + index" :href="'#tab-' + index">{{ category.name }}</v-tab>
            <p class="products-text">* Мы не занимаемся перепродажами, а помогаем сделать заказ с официального сайта Атоми по ценам, установленным компанией без наценок, посредников и переплат.</p>
          </v-tabs>
        </div>
      </v-col>
      <v-col cols="12" md="9" lg="9">
        <v-tabs-items style="overflow: visible" ref="productsWrapper" v-model="tab">
          <v-tab-item v-for="(category, index) of categories" :key="'tab-item' + index" :value="'tab-' + index">
            <v-row>
              <v-col cols="6" sm="4" md="4" xl="3" v-for="product of category.products" :key="'product-' + product.id" class="pa-md-3 pa-1">
                <CustomCard :product="product" :category="{id: category.id, name: category.name}"></CustomCard>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CategoryService from "@/services/category.service";
import CustomCard from "@/components/oftenUsed/CustomCard";

export default {
  components: {CustomCard},
  props: ['windowWidth'],
  data() {
    return {
      tab: 'tab-0',
      navbarHeight: '102px',
      categories: []
    }
  },
  created() {
    window.addEventListener('scroll', this.getNavbarHeight)
  },
  mounted() {
    CategoryService.getCategories().then(response => {
      this.categories = response.data
    })
  },
  destroyed() {
    window.removeEventListener('scroll', this.getNavbarHeight)
  },
  methods: {
    getNavbarHeight() {
      this.navbarHeight = document.querySelector('.navbar').clientHeight + 12 + 'px'
    },
    getImage(image) {
      return process.env.VUE_APP_API_URL + '/api/image/getProductImage/' + image
    }
  },
  watch: {
    tab() {
      window.scrollTo({top: 0, behavior: 'smooth'})
    }
  }
}
</script>

<style scoped lang="sass">
.products
  &-tabs
    position: sticky
  &-tab
    height: 48px !important
    width: 100%
    max-width: 100%
  &-text
    margin-top: 12px
    font-size: 16px
.v-tab
  border-radius: 0 8px 8px 0
  transition: 0.3s
  &:hover
    background-color: #018ABE
    color: #FFFFFF !important
  &--active
    background-color: #FFA000
    color: #FFFFFF
    border-radius: 0 8px 8px 0
    &:hover
      background-color: #FFA000


@media (max-width: 1903.98px)
  .products
    &-tab
      height: 48px !important
      font-size: 13px !important
    &-text
      margin-top: 8px
      font-size: 12px

@media (max-width: 1263.99px)
  .products
    &-tab
      height: 32px !important
      font-size: 9px !important
    &-text
      margin-top: 6px
      font-size: 10px


@media (max-width: 959.98px)
  .products
    &-tab
      height: auto !important
      font-size: 10px !important
    &-text
      display: none
  .v-tab
    border-radius: 0
    &--active
      border-radius: 0

@media (max-width: 599.98px)
  .products
    &-tabs
      height: auto
    &-tab
      font-size: 10px !important

</style>
