<template>
  <v-card class="product-card pt-3">
    <div class="product-card__image-wrapper">
      <v-img class="product-card__image" :src="image" cover></v-img>
    </div>
    <div class="product-card__info">
      <span class="product-card__title">{{ product.name }}</span>
      <span class="product-card__desc">{{ product.shortDesc }}</span>
    </div>
    <div class="product-card__actions" v-if="$route.name !== 'categories'">
      <button class="product-card__more" @click="toProductPage()">Подробнее<v-icon right>mdi-arrow-right</v-icon></button>
      <button class="product-card__cart" @click="addToCart()">В корзину<v-icon right>mdi-cart</v-icon></button>
    </div>
    <div class="product-card__actions" v-else>
      <button class="product-card__more" @click="$emit('edit-product', product.id, category.id)">Редактировать<v-icon right>mdi-square-edit-outline</v-icon></button>
      <button class="product-card__cart" @click="$emit('delete-product', product.id, category.id)">Удалить<v-icon right>mdi-delete-forever</v-icon></button>
    </div>
  </v-card>
</template>

<script>

export default {
  props: ['product', 'category'],
  computed: {
    image() {
      return process.env.VUE_APP_API_URL + '/api/image/getProductImage/' + this.product.preview
    },
    getValidPathForCategory() {
      return this.category.name.split(' ').join('-').toLowerCase() + '.' + this.category.id
    },
    getValidPathForProduct() {
      return this.product.name.split(' ').join('-').toLowerCase() + '.' + this.product.id
    },
  },
  methods: {
    toProductPage() {
      this.$router.push({name: 'product-page', params: {category: this.getValidPathForCategory, productName: this.getValidPathForProduct}})
    },
    addToCart() {
      let product = this.product
      product.category = this.category
      this.$store.dispatch('cart/addToCart', {product, count: 1})
    }
  }
}
</script>

<style lang="sass">
.product
  &-card
    display: flex
    flex-direction: column
    position: relative
    height: 100%
    width: 100%
    background: #F8F8F8
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15)
    border-radius: 12px
    padding: 16px
    &__image
      height: 100%
      width: 100%
      border-radius: 8px
      &-wrapper
        position: relative
        height: 260px
        border-radius: 8px
    &__title
      display: block
      margin-top: 12px
      font-family: 'Roboto Medium'
      font-size: 18px
      line-height: 130%
      letter-spacing: -0.01em
    &__desc
      display: block
      margin-top: 8px
      margin-bottom: 16px
      font-family: 'Roboto Regular'
      font-size: 16px
      line-height: 140%

    &__actions
      margin-top: auto
      display: flex
      justify-content: space-between

    &__more
      display: flex
      font-family: 'Roboto Regular'
      font-size: 16px
      color: #018ABE
      .v-icon
        font-size: 16px
        color: #018ABE
    &__cart
      display: flex
      font-family: 'Roboto Regular'
      font-size: 16px
      color: #018ABE
      .v-icon
        font-size: 16px
        color: #018ABE

@media (max-width: 1903.98px)
  .product
    &-card
      &__image
        &-wrapper
          height: 230px
      &__title
        margin-top: 10px
        font-size: 16px
        letter-spacing: -0.02em
      &__desc
        margin-top: 4px
        margin-bottom: 12px
        font-size: 14px

      &__more
        font-size: 14px
        .v-icon
          font-size: 14px
      &__cart
        font-size: 14px
        .v-icon
          font-size: 14px

@media (max-width: 1263.99px)
  .product
    &-card
      &__image
        &-wrapper
          height: 140px
      &__title
        margin-top: 10px
        font-size: 14px
        letter-spacing: -0.02em
      &__desc
        margin-top: 4px
        margin-bottom: 12px
        font-size: 10px

      &__more
        font-size: 10px
        .v-icon
          font-size: 10px
      &__cart
        font-size: 10px
        .v-icon
          font-size: 10px


@media (max-width: 959.98px)
  .product
    &-card
      &__image
        &-wrapper
          height: auto
      &__title
        margin-top: 10px
        font-size: 10px
        letter-spacing: 0
      &__desc
        margin-top: 4px
        margin-bottom: 12px
        font-size: 8px

      &__more
        font-size: 10px
        .v-icon
          margin-left: 4px
          font-size: 10px
      &__cart
        font-size: 10px
        .v-icon
          margin-left: 4px
          font-size: 10px

@media (max-width: 599.98px)
  .product
    &-card
      &__image
        &-wrapper
          height: auto
      &__title
        margin-top: 10px
        font-size: 10px
        letter-spacing: 0
      &__desc
        margin-top: 4px
        margin-bottom: 12px
        font-size: 8px

      &__more
        font-size: 8px
        .v-icon
          margin-left: 4px
          font-size: 8px
      &__cart
        font-size: 8px
        .v-icon
          margin-left: 4px
          font-size: 8px
</style>
